import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Tags = ({ tags }) => (
  <List>
    {tags.map((tag, i) => (
      <ListElement key={i}>
        <Link
          to={`/blog/?search=${encodeURIComponent(tag)}`}
          style={{ color: "black", boxShadow: "none" }}
        >
          {tag}
        </Link>
      </ListElement>
    ))}
  </List>
)

Tags.defaultProps = {
  tags: [],
}

const List = styled.ul`
  list-style: none;
  text-align: right;
  margin-bottom: 12px;
`

const ListElement = styled.li`
  display: inline-block;
  margin-left: 6px;
  background-color: #ffa69e;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 6px;
`

export default Tags
